import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import TeambookBlueButton from "../../../../default_components/TeambookBlueButton";
import Api from "../../../../../Api";
import ProjectSelect from "../../../../planner/ProjectSelect";
import { useSelectedProjectStore } from "../../../../../stores/planner";
import lsKeys from "../../../../default_values/defaultKeys";
import { TeambookReactSelect } from "../../../../default_components/TeambookReactSelect";
import { InputNumber } from "../../../../default_components/InputNumber";
import { WhiteTooltip } from "../../../../default_components/Tooltips";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";
import { DateTime } from "luxon";
import TeambookOutlinedButton from "../../../../default_components/TeambookOutlinedButton";
import RichTextEditor from "../../../../default_components/rich_text_editor/RichTextEditor";

const EditTimeLoggingWindow = ({
  projects,
  setIsSelected,
  editingLog,
  updateActuals,
  occupations,
  activities,
  setOpen,
}) => {
  const [loggedHours, setLoggedHours] = useState();
  const [selectedActivity, setSelectedActivity] = useState({});
  const [selectedOccupation, setSelectedOccupation] = useState({});
  const logNoteRef = useRef(editingLog?.comment || "<p></p>");
  const [forceRender, setForceRender] = useState(false);
  const { selectedProject, setSelectedProject, selectedTimeOff, setSelectedTimeOff, projectsType, setProjectsType } =
    useSelectedProjectStore();
  const lang = localStorage.getItem(lsKeys.LANGUAGE);

  const { t } = useTranslation();

  useEffect(() => {
    const selectedProject = projects.filter((pr) => pr.id === editingLog?.project_id)[0];

    if (selectedProject.kind === "time_off") {
      setSelectedTimeOff(selectedProject);
      setProjectsType("time_off");
    } else {
      setSelectedProject(selectedProject);
      setProjectsType("default");
    }

    setLoggedHours(editingLog?.duration / 60);
    logNoteRef.current = editingLog?.comment || "<p></p>";
    setForceRender((prev) => !prev);

    let editActivity = activities.filter((activity) => activity.id === editingLog?.payroll_item_id)[0];

    let editOccupation = occupations.filter((occupation) => occupation.id === editingLog?.task_id)[0];

    if (editActivity) {
      setSelectedActivity(editActivity);
    }

    if (editOccupation) {
      setSelectedOccupation(editOccupation);
    }
  }, [editingLog, occupations, activities]);

  const updateRequest = () => {
    Api.ActualLogs.update({
      id: editingLog.id,
      project_id: (projectsType === "time_off" ? selectedTimeOff : selectedProject).id,
      duration: loggedHours * 60,
      activity_id: selectedActivity?.value || selectedActivity?.id,
      occupation_id: selectedOccupation?.value || selectedOccupation?.id,
      note: logNoteRef.current,
    }).then(() => {
      updateActuals();
      closeWindow();
    });
  };

  const handleEditorChange = (value) => {
    logNoteRef.current = value;
  };

  const deleteLog = () => {
    Api.ActualLogs.delete({ id: editingLog.id }).then(() => {
      updateActuals();
      closeWindow();
    });
  };

  const closeWindow = () => {
    setIsSelected(false);
    setSelectedActivity({});
    setSelectedOccupation({});
    setOpen(false);
  };

  const selectDefaultProjects = () => {
    setProjectsType("default");

    let selectedProject = projects.filter((project) => project.active && project.kind !== "time_off")[0];

    if (selectedProject === null) {
      setSelectedProject(selectedProject);
    }
  };

  const selectTimeOffProjects = () => {
    setProjectsType("time_off");

    let selectedProject = projects.filter((project) => project.active && project.kind === "time_off")[0];

    if (selectedTimeOff === null) {
      setSelectedTimeOff(selectedProject);
    }
  };

  const getActivitiesOptions = () => {
    return activities.map((act) => ({
      value: act.id,
      label: act.name,
    }));
  };

  const getOccupationsOptions = () => {
    return occupations.map((act) => ({
      value: act.id,
      label: act.name,
    }));
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  return (
    <Dialog
      tabIndex="0"
      className="tb-default-dialog time-logging__dialog"
      open={true}
      onClose={closeWindow}
      id="editLogWindow"
    >
      <DialogTitle>
        <div className="time-logging__title-text">
          <p className="title">
            {t("actuals.edit_time_logging_header")}:{" "}
            <u>{DateTime.fromISO(editingLog.date).setLocale(lang).toFormat("d MMMM")}</u>
          </p>
        </div>

        <TeambookIcon name={icons.CROSS} onClick={closeWindow} />
      </DialogTitle>

      <DialogContent>
        <div className="time-logging-window__project-selector">
          <ProjectSelect
            projects={projects.filter((pr) => pr.active)}
            projectsType={projectsType}
            creationUsers={[editingLog.user_id]}
            selectDefaultProjects={selectDefaultProjects}
            selectTimeOffProjects={selectTimeOffProjects}
          />
        </div>

        <div className="time-logging-window__duration">
          <p style={{ margin: 0 }} className="actuals__duration-text-modal">
            {t("planning.duration_hours")}
          </p>

          <InputNumber
            id={"duration"}
            className="actuals__duration-input-modal"
            name="estimated"
            setValue={setLoggedHours}
            step={0.5}
            min={0.25}
            max={24}
            onKeyDown={handleEnterKey}
            value={loggedHours}
            roundValue={{
              isRoundValue: true,
              roundStep: 4,
            }}
          />
        </div>

        <div className="time-logging-window__project-selector">
          <p className="title">{t("actuals.service")}</p>

          <TeambookReactSelect
            isSearchable={true}
            options={getOccupationsOptions()}
            value={getOccupationsOptions().find((occupation) => occupation.value === selectedOccupation.id)}
            onChange={(occupation) => {
              setSelectedOccupation(occupation);
            }}
            height={38}
            maxMenuHeight={120}
          />
        </div>

        <div className="time-logging-window__project-selector">
          <WhiteTooltip title={t("actuals.payroll_info")}>
            <p className="title">{t("actuals.payroll")}</p>
          </WhiteTooltip>

          <TeambookReactSelect
            isSearchable={true}
            value={getActivitiesOptions().find((activity) => activity.value === selectedActivity.id)}
            options={getActivitiesOptions()}
            onChange={(activity) => {
              setSelectedActivity(activity);
            }}
            height={38}
            maxMenuHeight={120}
          />
        </div>

        <div className="time-logging-window__notes">
          <RichTextEditor
            key={forceRender}
            value={logNoteRef.current}
            onChange={handleEditorChange}
            placeholder={t("planning.comment")}
          />
        </div>
      </DialogContent>

      <DialogActions style={{ gap: 15 }}>
        {(!editingLog.approved || ["admin", "planner"].includes(localStorage.getItem(lsKeys.ROLE))) && (
          <>
            {!editingLog.approved && (
              <div onClick={deleteLog} className="teambook-icon-frame">
                <TeambookIcon
                  tooltipTitle={t("delete")}
                  alt="delete log"
                  color="alternative_default"
                  name={icons.TRASH}
                />
              </div>
            )}

            <TeambookOutlinedButton text={t("cancel")} style={{ flex: "1" }} onClick={() => closeWindow()} />

            <TeambookBlueButton
              id="saveEditLog"
              style={{ flex: "1" }}
              className="time-logging-window__time-confirm"
              text={editingLog.approved ? "Log is Approved" : t("save")}
              disabled={editingLog.approved}
              onClick={updateRequest}
            />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditTimeLoggingWindow;
