import React, { useState, useRef } from "react";
import { isWeekend } from "../../../../../lib/DateUtils";
import { useTranslation } from "react-i18next";
import CreateLogWindow from "./CreateLogWindow";
import EditTimeLoggingWindow from "./EditTimeLoggingWindow";
import { DateTime } from "luxon";
import ActualLog from "../../../ActualLog";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";

const ActualDay = ({
  date,
  beginningDate,
  projects,
  logs,
  user,
  updateActuals,
  occupations,
  activities,
  createActivity,
  createOccupation,
  changeContextMenu,
  changeTooltip,
  copyEnabled,
  copyLogRequest,
  showWeekendLogs,
  isLast,
}) => {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);
  const [editingLog, setEditingLog] = useState();
  const [editWindowOpened, setEditWindowOpened] = useState(false);
  const [createWindowOpened, setCreateWindowOpened] = useState(false);

  const isToday = () => {
    return DateTime.now().startOf("day").toISODate() === date.startOf("day").toISODate();
  };

  const openLoggingWindow = () => {
    if (!copyEnabled) {
      setIsSelected(true);
      setCreateWindowOpened(true);
    }
  };

  const openEditLoggingWindow = (log) => {
    setEditingLog(log);
    setEditWindowOpened(true);
  };

  const projectFor = (log) => {
    return projects.filter((p) => p.id === log.project_id)[0];
  };

  return (
    <>
      <div
        key={`month-${date.month}-day-${date.day}`}
        style={{
          width: `${100 / (showWeekendLogs ? 7 : 5)}%`,
          background: isSelected ? "var(--stroke)" : "var(--background-1)",
          borderBottomRightRadius: isLast && 7,
        }}
        className={`actuals__date-cell ${isLast ? "last" : ""} ${isToday() ? "actuals__date-cell_today " : ""} ${
          beginningDate.month !== date.month ? "past" : ""
        }`}
        last={date.weekday === 7 ? "true" : "false"}
        onClick={() => {
          copyEnabled && copyLogRequest(date);
        }}
      >
        <div className={`actuals__day-info ${isWeekend(date.toJSDate()) ? "day-weekend" : ""}`}>
          <div className="actuals__header">
            <p className="actuals__date">{date.day}</p>

            <p className="actuals__duration">{logs.reduce((ps, a) => ps + a.duration, 0) / 60}h</p>
          </div>

          <div>
            <div className="actuals__day-logs">
              {logs.map((log, index) => (
                <ActualLog
                  copyEnabled={copyEnabled}
                  log={log}
                  index={index}
                  updateActuals={updateActuals}
                  projectFor={projectFor}
                  date={date}
                  openEditLoggingWindow={openEditLoggingWindow}
                  changeContextMenu={changeContextMenu}
                  occupations={occupations}
                  activities={activities}
                  changeTooltip={changeTooltip}
                />
              ))}

              <div
                className="actual-log-empty-space"
                onClick={openLoggingWindow}
                style={{
                  height: `${110 - logs.length * 24}px`,
                  display: logs.length === 0 ? "flex" : "block",
                  alignItems: logs.length === 0 ? "center" : "unset",
                  justifyContent: logs.length === 0 ? "center" : "unset",
                }}
              >
                {logs.length === 0 && !copyEnabled && (
                  <div className="flex actuals__control-buttons">
                    <TeambookIcon name={icons.ADD} color="darkblue_new" style={{ width: 20, height: 20 }} />
                    <p>{t("actuals.new_time_logging")}</p>
                  </div>
                )}
              </div>
            </div>
            {logs.length > 0 && !copyEnabled && (
              <div className="flex actuals__control-buttons" onClick={openLoggingWindow}>
                <TeambookIcon name={icons.ADD} color="darkblue_new" style={{ width: 20, height: 20 }} />
                <p>{t("actuals.new_time_logging")}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {createWindowOpened && (
        <CreateLogWindow
          setIsSelected={setIsSelected}
          projects={projects}
          updateActuals={updateActuals}
          date={date}
          activities={activities}
          tasks={occupations}
          setOpen={setCreateWindowOpened}
        />
      )}

      {editWindowOpened && (
        <EditTimeLoggingWindow
          setIsSelected={setIsSelected}
          projects={projects}
          editingLog={editingLog}
          updateActuals={updateActuals}
          activities={activities}
          occupations={occupations}
          createActivity={createActivity}
          createOccupation={createOccupation}
          setOpen={setEditWindowOpened}
        />
      )}
    </>
  );
};

export default ActualDay;
