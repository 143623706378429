import WeekRow from "./month/WeekRow";
import ActualDays from "./month/ActualDays";
import { Week } from "./week/Week";
import React from "react";

const DefaultTimesheet = ({
  date,
  projects,
  occupations,
  activities,
  createActivity,
  createOccupation,
  setReportedHours,
  setApprovedHours,
  actualLogs,
  updateActuals,
  changeContextMenu,
  copyLogRequest,
  changeTooltip,
  copyEnabled,
  showWeekendLogs,
  account,
  actualsZoom,
}) => {
  return actualsZoom === "month" ? (
    <div
      style={{
        position: "relative",
        left: 0,
        marginLeft: 30,
        marginRight: 30,
        width: "100%",
        overflowY: "auto",
      }}
    >
      <WeekRow showWeekendLogs={showWeekendLogs} />

      <ActualDays
        changeContextMenu={changeContextMenu}
        projects={projects}
        date={date}
        occupations={occupations}
        activities={activities}
        createActivity={createActivity}
        createOccupation={createOccupation}
        setReportedHours={setReportedHours}
        setApprovedHours={setApprovedHours}
        actualLogs={actualLogs}
        updateActuals={updateActuals}
        changeTooltip={changeTooltip}
        copyEnabled={copyEnabled}
        copyLogRequest={copyLogRequest}
        showWeekendLogs={showWeekendLogs}
      />
    </div>
  ) : (
    <Week
      startDate={date.minus({ days: account.first_week_day === "Sunday" ? 1 : 0 })}
      logs={actualLogs}
      projects={projects}
      createActivity={createActivity}
      createOccupation={createOccupation}
      activities={activities}
      occupations={occupations}
      updateActuals={updateActuals}
      changeContextMenu={changeContextMenu}
      copyLogRequest={copyLogRequest}
      showWeekendLogs={showWeekendLogs}
      changeTooltip={changeTooltip}
      copyEnabled={copyEnabled}
    />
  );
};

export default DefaultTimesheet;
