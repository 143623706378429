const sortByDepartment = (users, departments) => {
  return [...users].sort((a, b) => {
    const departmentA = departments.find((d) => d.id === a.department_id);
    const departmentB = departments.find((d) => d.id === b.department_id);

    if (departmentA && !departmentB) return -1;
    if (!departmentA && departmentB) return 1;
    if (!departmentB && !departmentA) return 0;

    return departmentA.name.toLowerCase() < departmentB.name.toLowerCase() ? -1 : 1;
  });
};

export default sortByDepartment;
