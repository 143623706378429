import React, { useState, useEffect, useRef } from "react";
import "./projects.scss";
import "../users/users.scss";
import Header from "../header/Header";
import ProjectsFilterRow from "./sub_components/ProjectsFilterRow";
import ProjectForm from "./sub_components/ProjectForm";
import ProjectListBlock from "./sub_components/projects_table/ProjectListBlock";
import ClientsDialog from "./sub_components/Client/ClientsDialog";
import Api from "../../Api";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../default_components/LoadingComponent";
import { WhiteTooltip } from "../default_components/Tooltips";
import DeleteDialog from "../default_components/DeleteDialog";
import { sortProjects } from "./sub_components/lib/sortFunction";
import { SliceStringWithDots } from "../../lib/StringFormat";
import XLSExport from "../../lib/XLSExport";
import InfoTableColumnHeader from "./sub_components/projects_table/InfoTableColumnHeader";
import GetAllUsersRequest from "../planner/requests/GetAllUsersRequest";
import { useSelectedProjectStore, useTeambookFilter } from "../../stores/planner";
import { TeambookCheckbox } from "../default_components/TeambookCheckbox";
import { useProjectsVisibilityColumnsStore } from "../../stores/projects";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import { useAccountStore } from "../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../stores/notificationStore";
import filterProjects from "./sub_components/lib/filterProjects";
import { SwitchToPaidPlanBanner } from "../default_components/SwitchToPaidPlanBanner";
import { useProfileStore } from "../../stores/profileStore";
import lsKeys from "../default_values/defaultKeys";
import DidYouKnow from "../planner/default_components/DidYouKnow";
import { headerColumns } from "./sub_components/projects_table/lib/helperValues";

//TODO: can we get rid of either displayingProjects OR filteredProjects?
const ProjectsPage = () => {
  const { selectedProject, setSelectedProject } = useSelectedProjectStore();
  const { t } = useTranslation();
  const [emptyFiltered, setEmptyFiltered] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [projectsFormState, setProjectsFormState] = useState("show");
  const [chosenProject, setChosenProject] = useState(null);
  const [archivedSelect, setArchivedSelect] = useState("Active");
  const [clientsOpen, setClientsOpen] = useState(false);
  const [activeProjects, setActiveProjects] = useState();
  const [deactivatedProjects, setDeactivatedProjects] = useState();
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [displayingProjects, setDisplayingProjects] = useState([]);
  const [deletionOpen, setDeletionOpen] = useState(false);
  const [deletionProps, setDeletionProps] = useState({});
  const [exceedWindowOpened, setExceedWindowOpened] = useState(false);
  const [sortOrder, setSortOrder] = useState(localStorage.getItem(lsKeys.PROJECTS_SORT_ORDER) || "a");
  const [sortField, setSortField] = useState(localStorage.getItem(lsKeys.PROJECTS_SORT_FIELD) || "name");
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const { columnsVisibility } = useProjectsVisibilityColumnsStore();
  const [account, fetchAccount] = useAccountStore((state) => [state.account, state.fetchAccount], shallow);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const { filterValues, setFilterValue, filterType } = useTeambookFilter();
  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const didYouKnowRef = useRef({});

  useEffect(() => {
    getAllClients();
    GetAllUsersRequest(setUsers);
  }, []);

  useEffect(() => {
    localStorage.setItem(lsKeys.PROJECTS_SORT_FIELD, sortField);
    localStorage.setItem(lsKeys.PROJECTS_SORT_ORDER, sortOrder);
  }, [sortField, sortOrder]);

  useEffect(() => {
    setSelectedProjects([]);
    if (archivedSelect === "Active") {
      GetActiveProjects();
    } else {
      GetDeactivatedProjects();
    }
  }, [archivedSelect]);

  useEffect(() => {
    if (filterValues.length > 0 && displayingProjects.length === 0) {
      setEmptyFiltered(true);
    } else {
      setEmptyFiltered(false);
    }
  }, [filterValues, displayingProjects]);

  useEffect(() => {
    let projectsToFilter = archivedSelect === "Active" ? activeProjects : deactivatedProjects;

    if (projectsToFilter) {
      setDisplayingProjects(
        sortProjects(sortField, sortOrder, filterProjects(projectsToFilter, filterType, filterValues, t), account)
      );
      setFilteredProjects(
        sortProjects(sortField, sortOrder, filterProjects(projectsToFilter, filterType, filterValues, t), account)
      );
    }
  }, [filterValues, activeProjects, deactivatedProjects, filterType, archivedSelect]);

  useEffect(
    () => sortByField(sortField),
    [sortOrder, sortField, filteredProjects, activeProjects, deactivatedProjects, archivedSelect]
  );

  useEffect(() => {
    setFilterValue([]);
  }, [archivedSelect]);

  const selectProject = (project) => {
    if (selectedProjects.some(({ id }) => id === project.id)) {
      setSelectedProjects(selectedProjects.filter(({ id }) => id !== project.id));
    } else {
      setSelectedProjects([...selectedProjects, project]);
    }
  };

  const selectAllProjects = () => {
    if (filteredProjects.length !== selectedProjects.length) {
      setSelectedProjects(displayingProjects);
    } else {
      setSelectedProjects([]);
    }
  };

  const GetActiveProjects = () => {
    setIsLoaded(false);
    Api.Projects.active().then((response) => {
      setActiveProjects(response.data);
      setFilteredProjects(response.data);

      fetchAccount();
    });
  };

  const GetDeactivatedProjects = () => {
    setIsLoaded(false);
    Api.Projects.deactivated().then((response) => {
      let correctProjects = response.data
        .filter((project) => project.kind !== "time_off")
        .filter((project) => project.name !== "Google Integration");

      setDeactivatedProjects(correctProjects);
      setFilteredProjects(correctProjects);

      fetchAccount();
    });
  };

  const getAllClients = () => {
    Api.Clients.all().then(analyzeAllClientsResponse);
  };

  const deactivateProjectFunction = (project) => {
    Api.Projects.deactivate([project.id]).then(() => {
      closeShow();
      if (archivedSelect === "Active") {
        GetActiveProjects();

        if (selectedProject) {
          if (project.id === selectedProject.id) {
            setSelectedProject(null);
          }
        }
      } else {
        GetDeactivatedProjects();
      }
    });
  };

  const deleteProjectFunction = (project) => {
    Api.Projects.delete([project.id]).then((response) => {
      closeShow();

      if (archivedSelect === "Active") {
        GetActiveProjects();
      } else {
        GetDeactivatedProjects();

        if (selectedProject) {
          if (project.id === selectedProject.id) {
            setSelectedProject(null);
          }
        }
      }
    });
  };

  const bulkDeactivate = () => {
    Api.Projects.deactivate(selectedProjects.map(({ id }) => id)).then(() => {
      setSelectedProjects([]);
      GetActiveProjects();
      closeShow();

      if (selectedProject) {
        if (selectedProjects.map(({ id }) => id).includes(selectedProject.id)) {
          setSelectedProject(null);
        }
      }
      fetchAccount();
    });
  };

  const bulkReactivate = (project) => {
    if (selectedProjects.length + account?.active_projects > account?.subscription?.projects_available) {
      setExceedWindowOpened(true);
    } else {
      Api.Projects.activate(project ? [project.id] : selectedProjects.map(({ id }) => id)).then(() => {
        setSelectedProjects([]);
        GetDeactivatedProjects();
        closeShow();

        fetchAccount();
      });
    }
  };

  const bulkDelete = (id) => {
    Api.Projects.delete(selectedProjects.map(({ id }) => id)).then(() => {
      setSelectedProjects([]);
      GetDeactivatedProjects();
      closeShow();
      if (selectedProject) {
        if (selectedProjects.map(({ id }) => id).includes(selectedProject.id)) {
          setSelectedProject(null);
        }
      }

      fetchAccount();
    });
  };

  const analyzeAllClientsResponse = (response) => {
    setAllClients(response.data);
  };

  const sortByField = (field, order) => {
    setSortField(field);

    if (order) {
      if (sortOrder === "a") {
        setSortOrder("z");
      } else {
        setSortOrder("a");
      }
      setDisplayingProjects(sortProjects(field, order, filteredProjects, account));
    } else {
      setDisplayingProjects(sortProjects(field, sortOrder, filteredProjects, account));
    }
    setIsLoaded(true);
  };

  const ShowProject = (project) => {
    if (projectsFormState === "edit") {
    } else {
      setChosenProject(project);
      setProjectsFormState("show");
    }
  };

  const copyCalendarURL = (copyProject) => {
    let SERVER_ADDRESS;

    switch (process.env.REACT_APP_ENV) {
      case "production":
        SERVER_ADDRESS = "https://web.teambookapp.com";
        break;
      case "staging":
        SERVER_ADDRESS = "https://web.teambooktest.com";
        break;
      default:
        SERVER_ADDRESS = "http://localhost:3000";
        break;
    }

    navigator.clipboard.writeText(`${SERVER_ADDRESS}/projection/${copyProject.url}`);

    setInAppNotification(t("projects.copied_project_url"));
  };

  const openEditing = () => {
    setProjectsFormState("edit");
  };

  const closeEditing = () => {
    setProjectsFormState("");
  };

  const closeCreation = () => {
    setProjectsFormState("");
  };

  const closeShow = () => {
    setProjectsFormState("");
  };

  const loading = archivedSelect === "Active" ? activeProjects === undefined : deactivatedProjects === undefined;

  if (loading && allClients) {
    return (
      <div>
        <Header page={"users"} />

        <LoadingComponent />
      </div>
    );
  }

  const exportProjects = () => {
    XLSExport.ExportProjects(selectedProjects);
    setSelectedProjects([]);
  };

  const activateProject = (project) => {
    setDeletionOpen(true);

    setDeletionProps({
      deleteRequest: project ? () => bulkReactivate(project) : () => bulkReactivate(),
      buttonText: t("projects.reactivate_project"),
      contentText:
        project || selectedProjects.length === 1 ? (
          <>
            <p>
              {t("projects.reactivate_project_1")}
              <b>{project?.name || selectedProjects[0].name}</b>
              {t("projects.reactivate_project_2")}
            </p>
          </>
        ) : (
          <>
            <p>
              {t("projects.multiple_reactivate_project_1")}
              <WhiteTooltip
                placement="top"
                title={selectedProjects
                  .slice(0, 3)
                  .map(({ name }) => SliceStringWithDots(name, 16))
                  .toString()}
              >
                <b style={{ cursor: "pointer" }}>
                  {t("users.selected")}
                  {` ${selectedProjects.length}`}
                </b>
              </WhiteTooltip>

              {t("projects.multiple_reactivate_project_2")}
            </p>
          </>
        ),
    });
  };

  const deleteProject = (project) => {
    setDeletionOpen(true);

    if (project) {
      if (archivedSelect === "Active" && project.kind !== "time_off") {
        setDeletionProps({
          deleteRequest: deactivateProjectFunction,
          deletionObject: project,
          buttonText: t("projects.deactivate"),
          contentText: (
            <>
              <p>
                {t("projects.deactivate_confirmation_1")}
                <b>{project.name}</b>
                {t("projects.deactivate_confirmation_2")}
              </p>
            </>
          ),
        });
      } else {
        setDeletionProps({
          deleteRequest: deleteProjectFunction,
          deletionObject: project,
          buttonText: t("Delete"),
          contentText: (
            <>
              <p>
                {t("projects.delete_confirmation_1")}
                <b>{project.name}</b>
                {t("projects.delete_confirmation_2")}
              </p>
            </>
          ),
        });
      }
    } else {
      if (archivedSelect === "Active") {
        setDeletionProps({
          deleteRequest: bulkDeactivate,
          deletionObject: selectedProjects[0],
          buttonText: t("projects.archive"),
          contentText:
            selectedProjects.length === 1 ? (
              <>
                <p>
                  {t("projects.deactivate_confirmation_1")}
                  <b>{selectedProjects[0].name}</b>
                  {t("projects.deactivate_confirmation_2")}
                </p>
              </>
            ) : (
              <>
                <p>
                  {t("projects.multiple_deactivate_confirmation_1")}
                  <WhiteTooltip
                    placement="top"
                    title={selectedProjects
                      .slice(0, 3)
                      .map(({ name }) => SliceStringWithDots(name, 16))
                      .toString()}
                  >
                    <b style={{ cursor: "pointer" }}>
                      {t("users.selected")}
                      {` ${selectedProjects.length}`}
                    </b>
                  </WhiteTooltip>
                  {t("projects.multiple_deactivate_confirmation_2")}
                </p>
              </>
            ),
        });
      } else {
        setDeletionProps({
          deleteRequest: bulkDelete,
          deletionObject: selectedProjects[0],
          buttonText: t("Delete"),
          contentText:
            selectedProjects.length === 1 ? (
              <>
                <p>
                  {t("projects.delete_confirmation_1")}
                  <b>{selectedProjects[0].name}</b>
                  {t("projects.delete_confirmation_2")}
                </p>
              </>
            ) : (
              <>
                <p>
                  {t("projects.multiple_delete_confirmation_1")}
                  <WhiteTooltip
                    placement="top"
                    title={selectedProjects
                      .slice(0, 3)
                      .map(({ name }) => SliceStringWithDots(name, 16))
                      .toString()}
                  >
                    <b style={{ cursor: "pointer" }}>
                      {t("users.selected")}
                      {` ${selectedProjects.length}`}
                    </b>
                  </WhiteTooltip>
                  {t("projects.multiple_delete_confirmation_2")}
                </p>
              </>
            ),
        });
      }
    }
  };

  return (
    <div className="projects-page">
      <Header didYouKnowRef={didYouKnowRef} page={"projects"} />

      <ProjectsFilterRow
        setArchivedSelect={setArchivedSelect}
        setClientsOpen={setClientsOpen}
        archivedSelect={archivedSelect}
        openCreation={() => setProjectsFormState("create")}
        getActiveProjects={GetActiveProjects}
        filteredProjects={filteredProjects}
        setExceedWindowOpened={setExceedWindowOpened}
        exceedWindowOpened={exceedWindowOpened}
        setSelectedProjects={setSelectedProjects}
        activeProjects={activeProjects}
        deactivatedProjects={deactivatedProjects}
        getAllClients={getAllClients}
        clients={allClients.filter((client) => client.active)}
        openTimeOffCreation={() => setProjectsFormState("time_off_create")}
      />

      <div className="users-page__users-buttons">
        {selectedProjects.length > 0 && (
          <p style={{ margin: "0px 0px 0px 49px", textAlign: "left" }}>
            {selectedProjects.length}&nbsp;
            {t(`projects.selected_project${selectedProjects.length > 1 ? "s" : ""}_count`)}
          </p>
        )}

        {selectedProjects.length === 1 && selectedProjects[0]?.bookings_duration > 0 && (
          <TeambookIcon
            id="copyProjectCalendarPageButton"
            alt={"calendar"}
            color="alternative_default"
            name={icons.CALENDAR}
            className="users-page__user-button"
            onClick={() => copyCalendarURL(selectedProjects[0])}
            tooltipTitle={t("projects.copy_calendar")}
          />
        )}

        {selectedProjects.length > 0 && (
          <TeambookIcon
            id="exportProjectPageButton"
            className="users-page__user-button"
            color="alternative_default"
            name={icons.EXPORT_ARROW}
            onClick={exportProjects}
            alt="Export"
            tooltipTitle={t("dashboard.export_data")}
          />
        )}

        {selectedProjects.length > 0 && (
          <TeambookIcon
            id="deleteProjectPageButton"
            className="users-page__user-button"
            alt="deactivate"
            color="alternative_default"
            name={icons.TRASH}
            onClick={() => deleteProject()}
            tooltipTitle={archivedSelect === "Active" ? t("projects.archive") : t("delete")}
          />
        )}

        {selectedProjects.length > 0 && archivedSelect === "Archived" && (
          <TeambookIcon
            id="activateProjectPageButton"
            className="users-page__user-button"
            alt="deactivate"
            // color="alternative_default"
            name={icons.PROJECT_ADD}
            onClick={() => activateProject()}
            tooltipTitle={t("projects.activate_project")}
          />
        )}
      </div>

      <div style={{ overflow: emptyFiltered ? "unset" : "auto" }} className="projects-page__projects-content">
        {!emptyFiltered && (
          <div className="projects-page__project-list-header">
            <div className="user-list__fixed-field">
              <div id="selectAllProject" className="user-block__select-checkbox" onClick={selectAllProjects}>
                <TeambookCheckbox
                  checked={selectedProjects.length === displayingProjects.length && selectedProjects.length > 0}
                  onChange={selectAllProjects}
                />
              </div>

              <InfoTableColumnHeader
                sortingField={sortField}
                sortFunction={sortByField}
                sortingOrder={sortOrder}
                setSortOrder={setSortOrder}
                columnTitle={t("projects.name")}
                className={"projects-page__project-list-header_field name column-width-3"}
                columnField={"name"}
                isProjectsPage
                isCustomField
              />
            </div>
            {headerColumns(account.custom_project_fields, t).map((column) => {
              if (columnsVisibility[column.name] || column.name === "name") {
                return (
                  <InfoTableColumnHeader
                    style={column.style}
                    sortingField={sortField}
                    sortFunction={sortByField}
                    sortingOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    columnTitle={column.fieldText}
                    className={column.className}
                    columnField={column.columnField}
                    isProjectsPage
                    isCustomField={column.custom}
                  />
                );
              }
            })}
          </div>
        )}

        <div style={{ width: emptyFiltered ? "unset" : "fit-content" }} className="projects-page__project-list">
          {emptyFiltered ? (
            <div className="empty-filtered">
              <p className="empty-filtered-text">{t("users.users_not_found")}</p>
              <TeambookIcon color="blue" name={icons.EMPTY_FILTER_IMAGE} />
            </div>
          ) : isLoaded ? (
            displayingProjects?.map((item, key) => (
              <ProjectListBlock
                key={item.id}
                project={item}
                ShowProject={ShowProject}
                selectProject={selectProject}
                isSelected={selectedProjects.some(({ id }) => id === item.id)}
              />
            ))
          ) : (
            <LoadingComponent />
          )}
        </div>

        <DeleteDialog {...deletionProps} open={deletionOpen} setOpen={setDeletionOpen} />

        {((filteredProjects.length > 0 && chosenProject) ||
          projectsFormState === "create" ||
          projectsFormState === "time_off_create") && (
          <ProjectForm
            page={projectsFormState}
            project={chosenProject}
            projects={filteredProjects}
            onEdit={openEditing}
            activeClients={allClients.filter((client) => client.active)}
            setAllClients={setAllClients}
            closeEditing={closeEditing}
            closeCreation={closeCreation}
            getProjects={archivedSelect === "Active" ? GetActiveProjects : GetDeactivatedProjects}
            getActiveProjects={GetActiveProjects}
            closeShow={closeShow}
            setChosenProject={setChosenProject}
            deleteProject={deleteProject}
            users={users}
            copyCalendarURL={copyCalendarURL}
            reactivateProject={activateProject}
            archivedSelect={archivedSelect}
          />
        )}
      </div>

      <ClientsDialog
        clientsOpen={clientsOpen}
        setClientsOpen={setClientsOpen}
        clients={allClients.filter((client) => client.active)}
        setAllClients={setAllClients}
        getAllProjects={() => {
          if (archivedSelect === "Archived") {
            GetDeactivatedProjects();
          } else {
            GetActiveProjects();
          }
        }}
      />

      {profile && <DidYouKnow ref={didYouKnowRef} />}

      {profile && <SwitchToPaidPlanBanner />}
    </div>
  );
};

export default ProjectsPage;
