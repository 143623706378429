import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TeambookRadioButton from "../../default_components/TeambookRadioButton";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../stores/notificationStore";
import MobileContent from "./MobileContent";
import DesktopContent from "./DesktopContent";

const ENTER_KEY = 13;

const RegistrationModal = ({ register }) => {
  const mobileVersion = window.innerWidth < 768 || window.innerHeight < 768;

  return mobileVersion ? <MobileContent register={register} /> : <DesktopContent register={register} />;
};

export default RegistrationModal;
