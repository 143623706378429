import { useEffect, useState } from "react";
import { DateTime } from "luxon";

const TotalRow = ({ date, days, actuals }) => {
  const [totalValues, setTotalValues] = useState([]);

  useEffect(() => {
    setTotalValues(
      Array.apply(null, Array(days)).map((_, index) => {
        return formattedDuration(
          actuals
            .filter((actual) => actual.date === date.plus({ days: index }).toFormat("yyyy-MM-dd"))
            .reduce((acc, actual) => acc + actual.duration, 0)
        );
      })
    );
  }, [days, actuals]);

  const formattedDuration = (duration) => {
    if (duration === 0) {
      return 0;
    }

    return `${Math.floor(duration / 60)}.${duration < 10 ? "0" : ""}${((duration % 60) / 60) * 100}`;
  };

  return (
    <div className="actuals__by-project__totals-row">
      <div className="actuals__by-project__totals-row__spacer">
        <p>Total</p>
      </div>

      <div className="actuals__by-project__totals-row__days">
        {totalValues.map((value, index) => (
          <div
            key={`day-${index}`}
            className={`actuals__by-project__totals-row__day ${index === 0 ? "first-column" : ""}`}
          >
            <p className="actuals__by-project__totals-row__day-text" id={`total-text-${index}`}>
              {value}h
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TotalRow;
