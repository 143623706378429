import React, { useState } from "react";
import "./header_menu.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguagePicker } from "../../default_components/LanguagePicker";
import Menu from "@mui/material/Menu";
import DarkModeButton from "./DarkModeButton";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { DateTime } from "luxon";
import lsKeys from "../../default_values/defaultKeys";
import CalendarInegrationDialog from "../../default_components/teambook_calendar_integrations/CalendarInegrationDialog";
import { useProfileStore } from "../../../stores/profileStore";
import { shallow } from "zustand/shallow";

const HeaderMenu = ({
  anchorEl,
  userMenuOpened,
  closeMenu,
  openWhatsNew,
  changeLanguage,
  role,
  lang,
  logout,
  latestWhatsNew,
}) => {
  const { t } = useTranslation();

  const [lastTimeWhatsNewOpened, setLastTimeWhatsNewOpened] = useState(localStorage.getItem(lsKeys.LATEST_WHATS_NEW));
  const [calendarIntegrationOpen, setCalendarIntegrationOpen] = useState(false);

  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const whatsNewUpdate = () => {
    if (!lastTimeWhatsNewOpened) {
      return true;
    }

    return latestWhatsNew && DateTime.fromISO(latestWhatsNew) > DateTime.fromISO(lastTimeWhatsNewOpened);
  };

  const whatsNewClick = () => {
    openWhatsNew();
    setLastTimeWhatsNewOpened(DateTime.now().toISODate());
    localStorage.setItem(lsKeys.LATEST_WHATS_NEW, DateTime.now().toISODate());
  };

  const openCalendarIntegration = () => {
    setCalendarIntegrationOpen(true);
    closeMenu();
  };

  return (
    <Menu
      id="header-menu"
      anchorEl={anchorEl}
      keepMounted
      open={userMenuOpened}
      onClose={closeMenu}
      className="header-menu"
    >
      <div className="header-menu__section mobile_hidden">
        <NavLink to="/profile">
          <div className="header-menu__item-content" style={{ borderRadius: "10px 10px 0px 0px" }}>
            <TeambookIcon name={icons.PROFILE} />
            {t("header.profile")}
          </div>
        </NavLink>

        <a target="_blank" rel="noopener noreferrer" onClick={openCalendarIntegration}>
          <div className="header-menu__item-content">
            <TeambookIcon name={icons.LINK} />
            {t("header.connect_calendar")}
          </div>
        </a>

        <NavLink to="/my-week">
          <div className="header-menu__item-content">
            <TeambookIcon name={icons.WEEK} />
            {t("header.my_week")}
          </div>
        </NavLink>

        {["admin"].includes(role) && (
          <NavLink to="/settings">
            <div className="header-menu__item-content">
              <TeambookIcon name={icons.PLACE} />
              {t("header.account_settings")}
            </div>
          </NavLink>
        )}
      </div>

      <div className="header-menu__section">
        <a target="_blank" rel="noopener noreferrer" onClick={whatsNewClick}>
          <div className="header-menu__item-content" data-active={whatsNewUpdate()}>
            <TeambookIcon name={icons.WHATS_NEW} />
            {t("header.whats_new")}

            <div className="header-menu__item-content__whats-new-dot" />
          </div>
        </a>

        <a href={t("header.affiliation_url")} target="_blank" rel="noopener noreferrer">
          <div className="header-menu__item-content">
            <TeambookIcon name={icons.DOLLAR} />
            {t("header.affiliation")}
          </div>
        </a>

        <LanguagePicker
          className="desktop_hidden"
          changeLanguage={changeLanguage}
          currentLang={lang}
          flagStyle={{ width: 28, height: 28 }}
          style={{
            padding: "10px",
            gap: 10,
          }}
        />

        <div className="desktop_hidden">
          <a href={t("header.kb_link")} target="_blank" rel="noopener noreferrer">
            <div className="header-menu__item-content">{t("header.kb")}</div>
          </a>
        </div>
      </div>

      <DarkModeButton />

      <div className="header-menu__item-content" onClick={logout} style={{ borderRadius: "0px 0px 10px 10px" }}>
        <TeambookIcon name={icons.LOG_OUT} />
        <p>{t("header.logout")}</p>
      </div>

      <CalendarInegrationDialog open={calendarIntegrationOpen} setOpen={setCalendarIntegrationOpen} user={profile} />
    </Menu>
  );
};

export default HeaderMenu;
