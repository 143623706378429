import React from "react";
import "./my_week.scss";
import BookingIcons from "./BookingIcons";
import UsersProfileImage from "../default_components/UsersProfileImage";
import lsKeys from "../default_values/defaultKeys";
import { bookingStartTimeText } from "../planner/default_components/planner_right_side_modal/StartTimeBlock";

const MyWeekColumn = ({ columnDate, bookings, projects, columnClassName, tasks, managers }) => {
  const columnBookings = bookings.filter((booking) => booking.date === columnDate.toISODate());
  const amountHours = columnBookings.reduce((acc, booking) => acc + booking.duration, 0) / 60;

  const Booking = ({ booking, tasks }) => {
    const color = booking.project_color;
    const duration = booking.duration / 60;
    const bookingProject = projects.find((project) => project.id === booking.project_id);
    const manager = managers.find((manager) => manager.id === bookingProject.manager_id);

    const getColor = () => {
      const isDarkTheme = localStorage.getItem(lsKeys.THEME) === "dark";

      if (isDarkTheme) {
        return booking.tentative ? "#fff" : "#1A1B23";
      } else {
        return booking.tentative ? "#474747" : "#fff";
      }
    };

    const bookingsStyles = {
      background: booking.tentative ? "var(--background-1)" : color + "e6",
      border: booking.tentative && `2px solid ${color}`,
      color: booking.tentative ? "var(--text-14)" : "var(--background-1)",
      height: (booking.duration / 60) * 62,
    };

    return (
      <div className="column__booking-frame" style={bookingsStyles}>
        <div className="booking-info">
          <p style={{ color: getColor() }}>{booking.project_name}</p>
        </div>

        {booking.task_id && (
          <div style={{ color: getColor() }} className="task-description">
            [<p>{tasks.filter((t) => t.id === booking.task_id)?.[0]?.name}</p>]
          </div>
        )}

        <BookingIcons
          booking={booking}
          color={bookingsStyles.color}
          project={projects.filter((project) => project.id === booking.project_id)[0]}
          iconColor={booking.tentative ? "darkblue_new" : "myweek_icons"}
        />

        {booking.start_time?.toString() && (
          <p
            className="booking__start-time-block"
            style={{
              display: "flex",
              color: getColor(),
            }}
          >
            <p
              className="booking__start-time-text"
              style={{
                color: getColor(),
              }}
            >
              {bookingStartTimeText(booking)}
            </p>
          </p>
        )}

        {manager && (
          <div className="my-week__project-manager">
            <UsersProfileImage size={18} user={manager} userImage={manager.avatars?.[20]} className="manager-avatar" />
            <p style={{ color: getColor() }}>{manager.name}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={columnClassName(columnDate, "my-week__column", columnBookings)}>
      <div className="column__content">
        <div className="column__day-info">
          <p className="day-number">{columnDate.day}</p>
          <p className="amount-hours">{amountHours}h</p>
        </div>

        {columnBookings.map((booking) => (
          <Booking booking={booking} tasks={tasks} />
        ))}
      </div>
    </div>
  );
};

export default MyWeekColumn;
