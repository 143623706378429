import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import Api from "../../../../../Api";
import { TeambookReactSelect } from "../../../../default_components/TeambookReactSelect";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";
import RichTextEditor from "../../../../default_components/rich_text_editor/RichTextEditor";

const ProjectRow = ({
  project,
  date,
  days,
  actuals,
  updateActuals,
  tasks,
  projectPopupId,
  setProjectPopupId,
  payrollItemOptions,
  selectedPayrollItem,
  setSelectedPayrollItem,
}) => {
  const { t } = useTranslation();

  const [projectTime, setProjectTime] = useState();
  const [selectedTask, setSelectedTask] = useState();
  const [openedPopupIndex, setOpenedPopupIndex] = useState();
  const [notes, setNotes] = useState();

  let taskOptions = tasks
    .filter((t) => t.project_id === project.id || t.project_id === null)
    .map((task) => ({
      value: task.id,
      label: task.name,
    }));

  taskOptions.push({ value: null, label: t("actuals.no_task") });

  useEffect(() => {
    setProjectTime(defaultProjectTime());
  }, [actuals]);

  useEffect(() => {
    setProjectTime(defaultProjectTime());
  }, [project]);

  const setNewTime = (value, index) => {
    const newProjectTime = [...projectTime];
    newProjectTime[index].newValue = value;

    setProjectTime(newProjectTime);
  };

  const applyChanges = (index) => {
    // setOpenedPopupIndex(null);
    let timeValue = projectTime[index].newValue;

    if (timeValue === projectTime[index].currentValue) return;

    if (timeValue.match(/^[0-2]?\d$/)) {
      timeValue = `${timeValue}:00`;

      const newDuration = parseInt(timeValue) * 60;

      updateRequest(date.plus({ days: index }).toFormat("yyyy-MM-dd"), newDuration);
    } else if (timeValue.match(/^[0-2]?\d:[0-5]\d?$/)) {
      if (parseInt(timeValue[0]) === 2 && parseInt(timeValue[1]) > 4) {
        timeValue = "24:00";
      }

      if (timeValue.split(":")[1].length === 1) {
        timeValue = `${timeValue[0]}:${timeValue[1]}0`;
      }

      if (timeValue[1] === ":") {
        timeValue = `0${timeValue[0]}:${timeValue[2]}${timeValue[3]}`;
      }

      timeValue = `${timeValue[0]}${timeValue[1]}:${
        Math.floor((parseInt(timeValue[3]) * 10 + parseInt(timeValue[4])) / 15) * 15
      }`;

      const timeRange = timeValue.split(":");
      const newDuration = parseInt(timeRange[0]) * 60 + parseInt(timeRange[1]);

      updateRequest(date.plus({ days: index }).toFormat("yyyy-MM-dd"), newDuration);
    } else if (timeValue.match(/^[0-1]?\d?.\d\d?$/)) {
      const firstPart = timeValue.split(".")[0];

      if (timeValue.split(".")[1].length === 1) {
        timeValue = `${firstPart}.${timeValue.split(".")[1]}0`;
      }

      timeValue = `${firstPart}:${Math.floor(timeValue.split(".")[1] / 25) * 15}`;

      const timeRange = timeValue.split(":");
      const newDuration = parseInt(timeRange[0]) * 60 + parseInt(timeRange[1]);

      updateRequest(date.plus({ days: index }).toFormat("yyyy-MM-dd"), newDuration);
    } else {
      setProjectTime(defaultProjectTime());
    }
  };

  const updateRequest = (date, duration) => {
    Api.ActualLogs.insertPerProject({
      project_id: project.id,
      date: date,
      duration: duration,
      task_id: selectedTask?.value,
      payroll_item_id: selectedPayrollItem?.value,
      notes: notes,
    }).then(() => {
      updateActuals();
    });
  };

  const formattedDuration = (index) => {
    const actualDuration = actuals
      .filter(
        (actual) =>
          actual.project_id === project.id && actual.date === date.plus({ days: index }).toFormat("yyyy-MM-dd")
      )
      .reduce((acc, actual) => acc + actual.duration, 0);

    if (actualDuration === 0) {
      return "0";
    }

    return `${Math.floor(actualDuration / 60)}.${actualDuration < 10 ? "0" : ""}${((actualDuration % 60) / 60) * 100}`;
  };

  const defaultProjectTime = () => {
    return [...Array(days)].map((d, index) => ({
      currentValue: formattedDuration(index),
      newValue: formattedDuration(index),
    }));
  };

  const totalLoggedHours = () => {
    const startDate = date;
    const endDate = date.plus({ days: days });

    return (
      actuals
        .filter(
          (actual) =>
            actual.project_id === project.id &&
            DateTime.fromISO(actual.date) >= startDate &&
            DateTime.fromISO(actual.date) < endDate
        )
        .reduce((acc, actual) => acc + actual.duration, 0) / 60
    );
  };

  const totalApprovedHours = () => {
    const startDate = date;
    const endDate = date.plus({ days: days });

    return (
      actuals
        .filter(
          (actual) =>
            actual.project_id === project.id &&
            DateTime.fromISO(actual.date) >= startDate &&
            DateTime.fromISO(actual.date) < endDate &&
            actual.approved
        )
        .reduce((acc, actual) => acc + actual.duration, 0) / 60
    );
  };

  const openDetailsPopup = (projectId, index) => {
    setProjectPopupId(projectId);
    setOpenedPopupIndex(index);

    const actual = actuals.find(
      (actual) => actual.project_id === projectId && actual.date === date.plus({ days: index }).toFormat("yyyy-MM-dd")
    );

    if (!actual) return;

    if (actual.task_id) {
      setSelectedTask(taskOptions.find((task) => task.value === actual.task_id));
    }
    if (actual.payroll_item_id) {
      setSelectedPayrollItem(payrollItemOptions.find((payroll) => payroll.value === actual.payroll_item_id));
    }
    if (actual.comment) {
      console.log(actual.comment);
      setNotes(actual.comment);
    }
  };

  const closePopup = () => {
    setOpenedPopupIndex(null);
    setProjectPopupId(null);
  };

  return (
    <div className="actuals__by-project__projects-row" key={project.id}>
      <div className="actuals__by-project__projects-row__spacer">
        <div className="actuals__by-project__projects-row__spacer__project">
          <div className="spacer__project-color" style={{ backgroundColor: project.color }} />

          <p className="spacer__project-name">{project.name}</p>

          <div className="spacer__project-stats">
            <p>
              {totalLoggedHours()}h / {totalApprovedHours()}h
            </p>
          </div>
        </div>
      </div>

      {[...Array(days)].map((x, index, arr) => {
        const day = date.plus({ days: index });
        const isWeekend = day.weekday === 6 || day.weekday === 7;

        return (
          <div
            className={`actuals__by-project__projects-row__day ${index === 0 ? "first-column" : ""} ${
              isWeekend ? "weekend" : ""
            }`}
          >
            <input
              className="actuals__by-project__projects-row__day__input"
              value={projectTime?.[index]?.newValue}
              onChange={(event) => setNewTime(event.target.value, index)}
              onBlur={() => applyChanges(index)}
              onFocus={(event) => openDetailsPopup(project.id, index)}
            />
            {openedPopupIndex === index && projectPopupId === project.id && (
              <div className="params-popup">
                <div className="params-popup__service-payroll">
                  <div className="params-popup__field-name">
                    <p>{t("actuals.service")}</p>
                    <TeambookReactSelect
                      isSearchable={true}
                      options={taskOptions}
                      value={taskOptions.find((task) => task.value === selectedTask?.value)}
                      onChange={(task) => {
                        setSelectedTask(task);
                      }}
                      height={38}
                      maxMenuHeight={120}
                      tabIndex="-1"
                    />
                  </div>

                  <div className="params-popup__field-name">
                    <p>{t("actuals.payroll")}</p>
                    <TeambookReactSelect
                      isSearchable={true}
                      options={payrollItemOptions}
                      value={payrollItemOptions.find((payroll) => payroll.value === selectedPayrollItem?.value)}
                      onChange={(payroll) => {
                        setSelectedPayrollItem(payroll);
                      }}
                      height={38}
                      maxMenuHeight={120}
                      tabIndex="-1"
                    />
                  </div>

                  <TeambookIcon name={icons.CROSS} onClick={() => closePopup()} className="params-popup__close-popup" />
                </div>
                <div className="params-popup__notes">
                  <RichTextEditor value={notes || "<p></p>"} onChange={setNotes} placeholder={t("planning.comment")} />
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProjectRow;
