import React from "react";
import { WhiteTooltip } from "./Tooltips";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

const TeambookSortingTypes = ({ filterOrder, filterOptions, setFilterOrder, style, onClick }) => {
  const { t } = useTranslation();

  const filterOptionMapping = {
    alphabetical: {
      icon: filterOrder === "a" ? icons.SORT_AZ : icons.SORT_ZA,
      tooltip: t("planning.alphabetical_order"),
      setOrder: filterOrder === "a" ? "z" : "a",
      id: "alphabeticalOrder",
      is_selected: filterOrder === "a" || filterOrder === "z",
    },
    default: {
      icon: icons.SORT_MY_ORDER,
      tooltip: t("planning.my_order"),
      setOrder: "default",
      id: "myOrder",
      is_selected: filterOrder === "default",
    },
    department: {
      icon: icons.SORT_DEPARTMENT,
      tooltip: t("planning.department_order"),
      setOrder: "department",
      id: "departmentOrder",
      is_selected: filterOrder === "department",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        gap: 10,
        ...style,
      }}
      onClick={onClick}
    >
      {filterOptions.map((option) => (
        <WhiteTooltip title={filterOptionMapping[option].tooltip}>
          <div
            className="date-row__sort-icon"
            onClick={() => setFilterOrder(filterOptionMapping[option].setOrder)}
            id={filterOptionMapping[option].id}
          >
            <TeambookIcon
              name={filterOptionMapping[option].icon}
              style={{ opacity: filterOptionMapping[option].is_selected ? "1" : "0.3" }}
            />
          </div>
        </WhiteTooltip>
      ))}
    </div>
  );
};

export default TeambookSortingTypes;
