import { useContext, useState } from "react";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { PlannerContext } from "../../context/PlannerContext";
import UsersProfileImage from "../../../default_components/UsersProfileImage";
import { useTranslation } from "react-i18next";

export const PlannerDialogGroup = ({ group }) => {
  const [open, setOpen] = useState(false);
  const { users } = useContext(PlannerContext);
  const { t } = useTranslation();

  const [userId, bookings] = group;

  const user = users.find((user) => user.id === +userId);

  const switchDataShow = () => setOpen(!open);

  return (
    <div className="planner-info-modal__user-group">
      <div className="planner-info-modal__group-header">
        {user && <UsersProfileImage userImage={user.avatars["30"]} size={30} user={user} />}
        <p className="planer-info-modal__user-name">{user.name}</p>

        <p className="planner-info-modal__days">
          {bookings.length} {t("planning.days")}
        </p>

        <TeambookIcon onClick={switchDataShow} className="planner-info-modal__arrow" name={icons.ARROW_DOWN} />
      </div>

      {open && (
        <div className="planner-info-modal__days-data">
          {bookings.map((booking) => {
            return (
              <div className="planner-info-modal__day-info">
                <p className="planner-info-modal__booking-date">{booking.date}</p>

                <p className="planner-info-modal__booking-message">{booking.message}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
