import React, { useEffect, useState } from "react";
import Api from "../../Api";
import "./register.scss";
import "../default_styles/tb-colors.css";
import { Link, Navigate, useParams } from "react-router-dom";
import FormErrorMessage from "../default_components/FormErrorMessage";
import { languages } from "../default_values/Languages";
import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../default_components/TeambookBlueButton";
import PasswordRules from "./PasswordRules";
import TimeZones from "../default_values/TimeZones";
import RegistrationModal from "./registration_modal/RegistrationModal";
import TeambookTextForm from "../default_components/TeambookTextForm";
import lsKeys from "../default_values/defaultKeys";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import { useAccountStore } from "../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useProfileStore } from "../../stores/profileStore";
import GoogleButton from "../default_components/GoogleButton";

const ENTER_KEY = 13;

const Register = () => {
  const { lang } = useParams();
  const { t, i18n } = useTranslation();

  const [navigate, setNavigate] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [acceptedTC, setAcceptedTC] = useState(true);
  const [passwordRulesVisible, setPasswordRulesVisible] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [registrationModalOpened, setRegistrationModalOpened] = useState(false);
  const [pageToRedirect, setPageToRedirect] = useState("planners");
  const [isRequestSended, setIsRequestSended] = useState(false);
  const [googleCode, setGoogleCode] = useState();
  const [registrationWay, setRegistrationWay] = useState("default");

  const [fetchAccount] = useAccountStore((state) => [state.fetchAccount], shallow);
  const [fetchProfile] = useProfileStore((state) => [state.fetchProfile], shallow);

  useEffect(() => {
    if (lang && languages.includes(lang)) {
      localStorage.setItem("tb-lang", lang);
      i18n.changeLanguage(lang || "en");
    }

    if (localStorage.getItem("tb-lang")) {
      i18n.changeLanguage(localStorage.getItem("tb-lang") || "en");
    }

    document.title = t("auth.register_page_title");
  }, []);

  useEffect(() => {
    localStorage.setItem(lsKeys.GTM_MOVE_ACTION, "register");

    if (navigate) {
      // Push data to the Data Layer for GTM
      window.dataLayer.push({
        event: "navigationEvent", // Define a custom GTM event
        pageTitle: "Teambook Planner",
        pageUrl: "/planners",
      });
    }
  }, [navigate]);

  useEffect(() => {
    preloadImage("https://teambook-account-images-production.s3.amazonaws.com/assets/dark_blue/register_popup_bg.svg");
    preloadImage("https://teambook-account-images-production.s3.amazonaws.com/assets/blue/register_popup_bg.svg");
  }, []);

  const validateRegister = () => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!TimeZones.timeZonesArray.map((tz) => tz.value).includes(timezone)) {
      timezone = "UTC";
    }

    setIsRequestSended(true);

    Api.Auth.validate(firstName, lastName, email, password, lang, true, timezone)
      .then((response) => {
        if (response.data.ok) {
          submitRegistration();
        }
      })
      .catch((e) => {
        analyzeRegistrationError(e);
      })
      .finally(() => {
        setIsRequestSended(false);
      });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const redirectToMainPage = () => {
    setNavigate(true);

    if (process.env.REACT_APP_ENV === "production") {
      // Smartlook.init("96e34d02b515c91e65ebd2042c839315773d6c0d");
    }
  };

  const analyzeResponse = async (response, planningOption, registerEmail = email) => {
    if (response.status === 201) {
      trackCapterraConversion();
      gr("track", "conversion", { email: registerEmail });

      if (planningOption === "actuals") {
        setPageToRedirect("actuals");
      } else if (planningOption === "capacity") {
        setPageToRedirect("capacity");
      } else {
        setPageToRedirect("planners");
      }

      await fetchAccount();
      await fetchProfile();

      redirectToMainPage();
    } else {
      window.location.reload();
    }
  };

  const trackCapterraConversion = () => {
    var capterra_vkey = "7f501b908138e81f65d86be1ae8a26e9",
      capterra_vid = "2094932",
      ct = document.createElement("img");
    ct.classList.add("capterra-tracking");
    ct.src = "https://ct.capterra.com/capterra_tracker.gif?vid=" + capterra_vid + "&vkey=" + capterra_vkey;
    document.body.appendChild(ct);
  };

  const submitRegistration = () => {
    let letterRegex = /.*[^0-9].*/;
    let numberRegex = /.*[0-9].*/;
    let lengthPassword = password.length >= 8;
    let letterRule = letterRegex.test(password);
    let numberRule = numberRegex.test(password);
    closeErrorMessage();

    if (lengthPassword && letterRule && numberRule) {
      setRegistrationModalOpened(true);
    } else {
      alert("Please check your password to fit all rules.");
    }
  };

  const continueRegister = (params) => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let registerParameters = JSON.parse(JSON.stringify(params));

    if (!TimeZones.timeZonesArray.map((tz) => tz.value).includes(timezone)) {
      timezone = "UTC";
    }

    if (registerParameters) {
      if (registerParameters.account_users[0].first_name.length === 0) {
        registerParameters.account_users[0].first_name = "User";
      }
      if (registerParameters.account_users[0].last_name.length === 0) {
        registerParameters.account_users[0].last_name = "Example 1";
      }
      if (registerParameters.account_users[1].first_name.length === 0) {
        registerParameters.account_users[1].first_name = "User";
      }
      if (registerParameters.account_users[1].last_name.length === 0) {
        registerParameters.account_users[1].last_name = "Example 2";
      }
    }

    switch (registrationWay) {
      case "google":
        return Api.Auth.google_register(googleCode, lang, true, timezone, registerParameters)
          .then((res) => {
            analyzeResponse(res, params?.plan, res.data.email);
          })
          .catch((error) => {
            analyzeRegistrationError(error);
          });
      default:
        return Api.Auth.register(firstName, lastName, email, password, lang, true, timezone, registerParameters)
          .then((res) => analyzeResponse(res, params?.plan))
          .catch((error) => {
            analyzeRegistrationError(error);
          });
    }
  };

  const PasswordRulesShow = () => {
    setPasswordRulesVisible(true);
  };

  const switchPasswordShow = () => {
    setPasswordShown(!passwordShown);
  };

  const analyzeRegistrationError = (error) => {
    setRegistrationModalOpened(false);
    setErrorMessage(error.response.data.error.message);
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  const handleEnterPress = (e) => {
    if (e.keyCode === ENTER_KEY && !registrationModalOpened) {
      validateRegister();
    }
  };

  const preloadImage = (src) => {
    const img = new Image();
    img.src = src;
  };

  const registerUsingGoogle = (response) => {
    setGoogleCode(response.code);
    setRegistrationWay("google");
    setRegistrationModalOpened(true);
  };

  const proposals = [t("auth.proposal_1"), t("auth.proposal_2"), t("auth.proposal_3")];

  if (navigate) {
    return <Navigate to={`/${pageToRedirect}`} />;
  }

  return (
    <div onKeyDown={handleEnterPress} tabIndex="0" auto className="register">
      <div className="register__left-side mobile_hidden">
        <div className="auth__left-side-background" />

        <div className="register__proposal">
          <p className="if">{t("auth.if").toUpperCase()}</p>

          {proposals.map((text) => (
            <div className="proposal">
              <TeambookIcon name={icons.CHECK_M} color="blue" />
              <p>{text}</p>
            </div>
          ))}

          <div className="then-container">
            <p className="then">{t("auth.then").toUpperCase()}</p>
            <p>{t("auth.proposal_4")}</p>
          </div>
        </div>

        <div className="register__left-side-images">
          <TeambookIcon name={icons.TB_LOGO} color="blue" className="register__tb-logo" />

          {process.env.REACT_APP_ENV === "production" && (
            <a className="register-go-back" href="https://teambookapp.com">
              {t("auth.go_back_to_website")}
            </a>
          )}

          <TeambookIcon
            name={icons.NEW_REGISTER_BG}
            color="blue"
            className="register__left-side-image"
            style={{
              left: 0,
              right: 0,
              margin: "0 auto",
              bottom: "20%",
              height: "30%",
            }}
          />
        </div>
      </div>

      <div className="register__right-side">
        <TeambookIcon
          name={icons.TB_LOGO}
          color="blue"
          className="login__tb-logo desktop_hidden"
          style={{ top: 40, left: 30, position: "absolute" }}
        />

        <div className="register__form">
          <p className="register__input-side__register-text">{t("auth.create_account")}</p>
          <Link to="/login" className="register__input-side__login-link">
            {t("auth.or_login")}
          </Link>

          <div style={{ marginTop: 50 }} className="register-form__inputs">
            <GoogleButton
              successGoogle={registerUsingGoogle}
              errorGoogle={() => {}}
              text={t("auth.continue_google")}
              id={"google_account"}
            />

            <div className="login-register__or-line">
              <div className="line" />
              <p>{t("auth.or_signup_with").toLowerCase()}</p>
              <div className="line" />
            </div>

            <div className="register-form__row">
              <TeambookTextForm
                id="register-first-name"
                style={{ width: 160 }}
                fieldName={t("auth.first_name")}
                fieldValue={firstName}
                onChange={(val) => {
                  setFirstName(capitalizeFirstLetter(val));
                }}
                placeholder="John"
              />

              <TeambookTextForm
                id="register-last-name"
                style={{ width: 160 }}
                fieldValue={lastName}
                onChange={(val) => setLastName(capitalizeFirstLetter(val))}
                fieldName={t("auth.last_name")}
                placeholder="Smith"
              />
            </div>

            <div className="register-form__row">
              <TeambookTextForm
                id="register-email"
                style={{ width: "100%" }}
                fieldName={t("auth.business_email")}
                fieldValue={email}
                onChange={setEmail}
                placeholder="john_smith@mail.com"
              />
            </div>

            <div style={{ display: "flex", position: "relative" }} className="register-form__row">
              <TeambookTextForm
                style={{ width: "100%" }}
                fieldName={t("auth.password")}
                placeholder={t("auth.password_placeholder")}
                type={passwordShown ? "text" : "password"}
                fieldValue={password}
                onChange={setPassword}
                id="password-field"
                onFocus={PasswordRulesShow}
                isPassword
                setShowPass={switchPasswordShow}
                showPass={passwordShown}
              />
            </div>

            <PasswordRules password={password} visible={passwordRulesVisible} />

            {errorMessage !== null ? (
              <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} style={{ marginTop: 10 }} />
            ) : null}
          </div>

          <div className="register-privacy-policy-text">
            <p
              style={{
                fontSize: 12,
                marginRight: 10,
                whiteSpace: "nowrap",
                marginBottom: 10,
              }}
              onClick={() => setAcceptedTC(!acceptedTC)}
            >
              {t("auth.by_clicking_1")}&nbsp;
              <a href={t("auth.link_1")} target="_blank" rel="noopener noreferrer">
                {t("auth.by_clicking_2")}
              </a>
              &nbsp;{t("auth.and")}&nbsp;
              <br />
              <a href={t("auth.link_2")} target="_blank" rel="noopener noreferrer">
                {t("auth.by_clicking_3")}
              </a>
            </p>
          </div>

          <TeambookBlueButton
            onClick={validateRegister}
            id="create_org_btn"
            style={{
              width: "100%",
              marginTop: 10,
            }}
            disabled={isRequestSended}
            text={t("auth.create_account_trial")}
            textId="create_org_btn"
          />
        </div>
      </div>

      {registrationModalOpened && (
        <RegistrationModal close={() => setRegistrationModalOpened(false)} register={continueRegister} />
      )}
    </div>
  );
};

export default Register;
