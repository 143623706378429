import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TeambookBlueButton from "../../TeambookBlueButton";
import Dialog from "@mui/material/Dialog";
import TeambookTextForm from "../../TeambookTextForm";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { useTranslation } from "react-i18next";
import Api from "../../../../Api";
import { useTeambookFilter } from "../../../../stores/planner";

const SaveFilterModal = ({ setSaveFilterModalOpen, filterPage }) => {
  const { t } = useTranslation();
  const [filterName, setFilterName] = useState("");

  const saveFilter = () => {
    if (filterName.length === 0) {
      alert(t("planning.empty_filter"));
    } else {
      Api.Users.create_saved_filter(
        filterName,
        filterPage,
        JSON.stringify(useTeambookFilter.getState().filterValues)
      ).then(() => {
        setSaveFilterModalOpen(false);
      });
    }
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="tb-default-dialog leave-modal__dialog"
      onClose={() => setSaveFilterModalOpen(false)}
    >
      <DialogTitle>
        <div className="flex">
          <p>{t("planning.save_filter")}</p>
        </div>

        <TeambookIcon name={icons.CROSS} onClick={() => setSaveFilterModalOpen(false)} />
      </DialogTitle>

      <DialogContent style={{ margin: "20px 0px" }}>
        <TeambookTextForm
          style={{ width: "100%" }}
          fieldName={t("planning.filter_name")}
          fieldValue={filterName}
          onChange={setFilterName}
        />
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton onClick={saveFilter} text={t("save")} />
      </DialogActions>
    </Dialog>
  );
};

export default SaveFilterModal;
