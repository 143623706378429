const MonthRow = ({ date, zoom, days }) => {
  const calculateMonths = () => {
    if (zoom === "week") {
      if (date.plus({ weeks: 1 }).month !== date.month) {
        const firstMonthWidth = ((date.endOf("month").day - date.day) / 7) * 100;

        return [
          { month: date.toFormat("LLLL"), span: firstMonthWidth },
          { month: date.plus({ weeks: 1 }).toFormat("LLLL"), span: 100 - firstMonthWidth },
        ];
      }
    }

    return [{ month: date.toFormat("LLLL"), span: 100 }];
  };

  return (
    <div className="actuals__by-project__month-row">
      <div className="actuals__by-project__month-row__spacer" />

      <div className="actuals__by-project__month-row__months">
        {Array.apply(null, Array(days)).map((_, index) => (
          <div key={`day-${index}`} className="actuals__by-project__days-row__day">
            {index === 0 && <p className="actuals__by-project__month-row__month">{calculateMonths()[0].month}</p>}
            {index === 6 && <p className="actuals__by-project__month-row__month">{calculateMonths()[1]?.month}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthRow;
