import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState, useRef, memo } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../Api";
import Cross from "../default_images/white_cross.svg";
import FormErrorMessage from "./FormErrorMessage";
import TeambookBlueButton from "./TeambookBlueButton";
import TeambookOutlinedButton from "./TeambookOutlinedButton";
import BlackCross from "../default_images/cross.svg";
import { DateTime } from "luxon";
import lsKeys from "../default_values/defaultKeys";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { TeambookBanner } from "./TeambookBanner";

const BANNER_SHOW_COUNT = "banner-show-count";
const BANNER_LAST_SHOW = "banner-last-show";
const BANNER_QUARTER_SHOW = "banner-quarter-show";

function getQuarter(date = new Date()) {
  return Math.floor(date.getMonth() / 3 + 1);
}

export const UserFeedbackBanner = memo(({}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const isBannerOpenedToday = () => {
    let lastDateBannerOpened = localStorage.getItem(BANNER_LAST_SHOW);

    if (lastDateBannerOpened) {
      if (new Date(lastDateBannerOpened).getDate().valueOf() === new Date().getDate().valueOf()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isBannerShowCountOverload = () => {
    let bannerShowCount = +localStorage.getItem(BANNER_SHOW_COUNT) || 0;

    if (bannerShowCount < 5) {
      return false;
    } else {
      return true;
    }
  };

  const isOpenedInThisQuarter = () => {
    let lastShowQuarter = +localStorage.getItem(BANNER_QUARTER_SHOW);

    if (lastShowQuarter) {
      if (lastShowQuarter !== getQuarter()) {
        localStorage.removeItem(BANNER_LAST_SHOW);
        localStorage.removeItem(BANNER_SHOW_COUNT);
        localStorage.removeItem(lsKeys.BANNER_CLOSED_BY_USER);
      }
    }

    localStorage.setItem(BANNER_QUARTER_SHOW, getQuarter());
  };

  const isBannerClosedByUser = () => {
    if (localStorage.getItem(lsKeys.BANNER_CLOSED_BY_USER)) {
      return localStorage.getItem(lsKeys.BANNER_CLOSED_BY_USER) === "true";
    } else {
      return false;
    }
  };

  const closeBanner = () => {
    localStorage.setItem(lsKeys.BANNER_CLOSED_BY_USER, true);
    localStorage.setItem(BANNER_LAST_SHOW, new Date());
    localStorage.setItem(BANNER_SHOW_COUNT, +localStorage.getItem(BANNER_SHOW_COUNT) + 1);
    setShow(false);
  };

  const openModal = () => {
    isOpenedInThisQuarter();
    const userCreationDate = DateTime.fromISO(profile.created_at);

    const isAccountNew = DateTime.fromISO(userCreationDate).plus({ month: 1 }).ts > DateTime.now().ts;

    if (!isAccountNew && !isBannerOpenedToday() && !isBannerShowCountOverload() && !isBannerClosedByUser()) {
      return true;
    } else {
      return false;
    }
  };

  const sendMessage = () => {
    setErrorMessage("");
    Api.Users.send_review(message)
      .then(() => {
        localStorage.setItem(lsKeys.BANNER_CLOSED_BY_USER, true);

        setIsFeedbackModalOpen(false);
      })
      .catch((e) => {
        setErrorMessage(e.response.data.error.message);
      });
  };

  const [show, setShow] = useState(openModal());

  const closeTimeout = useRef();

  closeTimeout.current = setTimeout(() => {
    setShow(false);
  }, 30 * 1000);

  return (
    <>
      <TeambookBanner
        bannerContent={
          <>
            <div
              style={{
                color: "#fff",
                fontSize: 18,
                margin: "0 auto",
                display: "flex",
                alignItems: "center",
                gap: 40,
              }}
            >
              <p style={{ margin: 0 }}>{t("auth.banner_text")}</p>
              <TeambookOutlinedButton
                text={t("auth.banner_button_text")}
                onClick={() => {
                  setIsFeedbackModalOpen(true);
                  setShow(false);
                }}
              />
            </div>
            <img
              style={{
                width: 24,
                height: 24,
                cursor: "pointer",
                position: "absolute",
                right: 75,
              }}
              src={Cross}
              onClick={closeBanner}
            />
          </>
        }
        bannerOpened={show}
      />

      {isFeedbackModalOpen && (
        <Dialog onClose={() => setIsFeedbackModalOpen(false)} className="tb-default-dialog" open={true}>
          <DialogTitle>
            <p>{t("auth.feedback")}</p>
            <img src={BlackCross} onClick={() => setIsFeedbackModalOpen(false)} />
          </DialogTitle>
          <DialogContent className="tb-banner__content" style={{ margin: "15px 0px 20px 0px", width: 600 }}>
            <textarea
              style={{
                width: "100%",
                height: 150,
                resize: "none",
                fontFamily: "Montserrat",
                fontSize: 14,
                border: "1px solid #c8d2e6",
                borderRadius: 8,
                marginTop: 5,
              }}
              placeholder={t("auth.feedback_placeholder")}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {errorMessage && <FormErrorMessage text={errorMessage} />}
          </DialogContent>
          <DialogActions>
            <div style={{ display: "flex", gap: 20 }}>
              <TeambookBlueButton text={t("auth.banner_send")} onClick={sendMessage} />
              <TeambookOutlinedButton onClick={() => setIsFeedbackModalOpen(false)} text={t("cancel")} />
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
});
