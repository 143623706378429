import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProjectsBlock from "./ProjectsBlock";
import Api from "../../../Api";
import AddProjectsDialog from "./AddProjectsDialog";
import CopyPlannerDialog from "./CopyPlannerDialog";
import { recalculateCapacityArray } from "../lib/recalculateCapacityArray";
import { calculateDaysInMonth, calculateHeatStyles } from "../lib/calculateHeatStyles";
import UsersProfileImage from "../../default_components/UsersProfileImage";
import { getCorrectTextLocale } from "../index";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { useCapacityStore } from "../../../stores/capacity";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../stores/accountStore";
import { useProfileStore } from "../../../stores/profileStore";
import { ar } from "date-fns/locale";
import { DateTime } from "luxon";
import YearSeparator from "../default_components/YearSeparator";

const UsersBlock = ({
  user,
  bookings,
  projects,
  date,
  capacityReservations,
  jumpToProject,
  selectedViewType,
  usersShown,
  isLastUser,
}) => {
  const userRole = localStorage.getItem("tb-role") || "regular";
  const { t } = useTranslation();
  const [activeProjects, setActiveProjects] = useState([]);
  const [calculatedDurations, setCalculatedDurations] = useState([]);
  const [addProjectsOpened, setAddProjectsOpened] = useState(false);
  const [userReservations, setUserReservations] = useState(capacityReservations.filter((r) => r.user_id === user.id));
  const [copyPlannerOpened, setCopyPlannerOpened] = useState(false);
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const [showTimeOffs] = useCapacityStore((state) => [state.showTimeOffs], shallow);

  useEffect(() => {
    if (activeProjects.length === 0) {
      let projectIds = bookings.map((b) => b.project_id);
      projectIds = [
        ...projectIds,
        ...capacityReservations.filter((cr) => cr.days_reserved > 0).map((cr) => cr.project_id),
      ];

      let filteredProjects = projects.filter((pr) => projectIds.includes(pr.id));

      setActiveProjects([
        ...filteredProjects.filter((pr) => pr.kind !== "time_off").sort((x, y) => (x.name > y.name ? 1 : -1)),
        ...filteredProjects.filter((pr) => pr.kind === "time_off").sort((x, y) => (x.name > y.name ? 1 : -1)),
      ]);
    }
  }, [bookings, projects, capacityReservations]);

  useEffect(() => {
    setUserReservations(capacityReservations.filter((r) => r.user_id === user.id));
  }, [capacityReservations]);

  useEffect(() => {
    updateCalculations(userReservations);
  }, [userReservations, date]);

  const updateUserReservations = () => {
    Api.CapacityReservations.get({
      user_ids: [user.id],
      start_date: date.toISODate(),
      end_date: date.plus({ years: 2 }).toISODate(),
    }).then((res) => {
      setUserReservations(res.data);
      updateCalculations(res.data);
      // updateCapacityReservations([user]);
    });
  };

  const copyPlanningValues = () => {
    setCopyPlannerOpened(true);
  };

  const updateCalculations = (reservations) => {
    setCalculatedDurations(recalculateCapacityArray(date, reservations, selectedViewType));
  };

  const addProjects = (newProjects) => {
    setActiveProjects([...activeProjects, ...projects.filter((pr) => newProjects.map((p) => p.id).includes(pr.id))]);
    setAddProjectsOpened(false);
  };

  const showAddProjectMenu = () => {
    return ["admin", "planner"].includes(userRole) || (userRole === "self_planner" && user.id === profile?.id);
  };

  return (
    <div className="users-block">
      <div className="users-block__headline">
        <div
          style={{
            borderBottomLeftRadius: isLastUser && activeProjects.length === 0 ? 7 : 0,
            borderBottom: isLastUser && activeProjects.length === 0 ? "1px solid var(--stroke)" : "",
          }}
          className="users-block-main__headline__user-name"
        >
          <div className="flex users-name-block">
            <UsersProfileImage
              size={30}
              user={user}
              userImage={user.avatars["30"]}
              style={{
                marginLeft: "30px",
              }}
              className={"capacity-user-image"}
            />

            <p className="dark">
              {user.first_name} <br /> {user.last_name}
            </p>
          </div>

          <div className="flex" style={{ gap: "10px" }}>
            {showAddProjectMenu() && (
              <div
                className="dashboard__tb-button "
                onClick={() => setAddProjectsOpened(true)}
                style={{
                  marginRight: "12px",
                  border: "unset",
                }}
                title={t("planning.capacity.add_project")}
              >
                <TeambookIcon
                  id="add-user-to-group"
                  className="add-user"
                  name={icons.ADD}
                  width={18}
                  color="alternative_default"
                />
              </div>
            )}
          </div>
        </div>

        {[...Array(selectedViewType)].map((x, i, arr) => {
          const userTotalDays = calculateDaysInMonth(date.plus({ months: i }), user, account);
          const styleBg = calculateHeatStyles(account, user, date.plus({ months: i }), calculatedDurations[i] || 0);
          const toolTipMessage = getCorrectTextLocale(styleBg, t, userTotalDays, userTotalDays === 0);

          return (
            <div
              style={{
                borderLeft: "1px solid transparent",
                // borderRight: arr.length - 1 === i ? "1px solid var(--stroke)" : "1px solid transparent",
                borderBottomRightRadius: arr.length - 1 === i && isLastUser && activeProjects.length === 0 ? 7 : 0,
                position: "relative",
              }}
              className={`table-cell ${styleBg} user-row`}
              title={toolTipMessage}
            >
              <p>{calculatedDurations[i] || "0"} d</p>
              {date.plus({ months: i }).month === 12 && i !== selectedViewType - 1 && <YearSeparator />}
            </div>
          );
        })}
      </div>

      {activeProjects
        .filter((pr) => pr.kind !== "time_off" || showTimeOffs)
        .map((pr, i) => (
          <ProjectsBlock
            project={pr}
            date={date}
            capacityReservations={userReservations}
            user={user}
            updateUserReservations={updateUserReservations}
            jumpToProject={jumpToProject}
            selectedViewType={selectedViewType}
            isLastProject={i === activeProjects.length - 1 && user.id === usersShown[usersShown.length - 1].id}
          />
        ))}

      {addProjectsOpened && (
        <AddProjectsDialog
          setDialogOpened={setAddProjectsOpened}
          projects={projects.filter((pr) => !activeProjects.includes(pr))}
          addProjects={addProjects}
        />
      )}

      {copyPlannerOpened && (
        <CopyPlannerDialog
          setDialogOpened={setCopyPlannerOpened}
          date={date}
          user={user}
          updateReservations={updateUserReservations}
          dialogType="individual"
        />
      )}
    </div>
  );
};

export default UsersBlock;
