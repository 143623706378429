import { useTranslation } from "react-i18next";
import { usePlannerInfoModal } from "../../../../stores/planner";
import { DateTime } from "luxon";

const openInfoModal = (skippedBookings) => {
  usePlannerInfoModal.getState().setInfoModalData(skippedBookings);
  usePlannerInfoModal.getState().setInfoModalOpened(true);
};

const openInfoModalIfNeeded = (response, indexesWithBookingsCreated, plannerDispatcher, t) => {
  if (response.data?.response?.skipped_bookings?.length > 0) {
    const skippedBookingInfo = response.data.response.skipped_bookings
      .map((skippedBooking) => {
        if (skippedBooking.reason === "time_off") {
          return {
            userId: skippedBooking.user_id,
            date: DateTime.fromISO(skippedBooking.date).toFormat("dd/MM/yyyy"),
            message: t("planning.timeoff_user"),
            type: "skipped",
          };
        } else if (skippedBooking.reason === "holiday") {
          return {
            userId: skippedBooking.user_id,
            date: DateTime.fromISO(skippedBooking.date).toFormat("dd/MM/yyyy"),
            message: t("planning.holiday_user"),
            type: "skipped",
          };
        } else {
          return null;
        }
      })
      .filter((value) => value);

    const createdBookingsWithNoTime = response.data.response.skipped_bookings
      .map((skippedBooking) => {
        if (skippedBooking.reason === "not_enough_space") {
          return {
            userId: skippedBooking.user_id,
            bookingId: response.data.bookings.find(
              (b) => b.user_id === skippedBooking.user_id && b.date === skippedBooking.date
            ).id,
            indexesWithBookingsCreated: indexesWithBookingsCreated,
            plannerDispatcher: plannerDispatcher,
            message: `User will have overtime on ${DateTime.fromISO(skippedBooking.date).toFormat("dd/MM/yyyy")}.`,
            type: "not_enough_space",
          };
        }
      })
      .filter((value) => value);

    const infoArray = [...skippedBookingInfo, ...createdBookingsWithNoTime];

    if (infoArray.length > 0) {
      openInfoModal(infoArray);
    }
  }
};

export const analyzeCreateResponse = (response, indexesWithBookingsCreated, plannerDispatcher, t) => {
  openInfoModalIfNeeded(response, indexesWithBookingsCreated, plannerDispatcher, t);
};
