import "../../my_week/my_week.scss";
import Api from "../../../Api";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import React, { useEffect, useMemo, useState } from "react";
import GoogleScreen from "./googleScreen.png";
import TeambookTextForm from "../TeambookTextForm";
import TeambookBlueButton from "../TeambookBlueButton";
import TeambookOptionsLine from "../TeambookOptionsLine";
import GoogleTab from "./tabs/GoogleTab";
import OutlookTab from "./tabs/OutlookTab";

export default function CalendarInegrationDialog({ open, setOpen, user }) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("google");
  const [calendarUrl, setCalendarUrl] = useState();

  useEffect(() => {
    setCalendarUrl(Api.Calendar.link(user.calendar_token));
  }, []);

  const CloseDialog = () => {
    setOpen(false);
  };

  const tabOptions = () => {
    return [
      {
        text: "Google Calendar",
        value: "google",
        onClick: () => setSelectedTab("google"),
        icon: <TeambookIcon name={icons.GOOGLE} />,
      },
      {
        text: "Outlook",
        value: "outlook",
        onClick: () => setSelectedTab("outlook"),
        icon: <TeambookIcon name={icons.OUTLOOK} />,
      },
      {
        text: "Calendar URL",
        value: "general",
        onClick: () => setSelectedTab("general"),
        icon: <TeambookIcon name={icons.LINK} />,
      },
    ];
  };

  return (
    <Dialog
      className="tb-default-dialog calendar-integration-modal"
      open={open}
      onClose={CloseDialog}
      aria-label="calendar integration modal"
    >
      <DialogTitle>
        <p>{t("my_week.calendar_integration")}</p>
        <TeambookIcon name={icons.CROSS} onClick={CloseDialog} />
      </DialogTitle>

      <DialogContent>
        <TeambookOptionsLine options={tabOptions()} selectedOption={selectedTab} />

        <div>
          <GoogleTab open={selectedTab === "google"} />

          <OutlookTab open={selectedTab === "outlook"} />

          {selectedTab === "general" && (
            <div className="calendar-integration__content">
              <div className="integration__firstStep">
                <p>1. {t("my_week.copy_link")}</p>

                <div className="integration__urlForm">
                  <TeambookTextForm fieldValue={calendarUrl} width={"320px"} />

                  <TeambookBlueButton
                    className="integration__copyButton"
                    onClick={navigator.clipboard.writeText(calendarUrl)}
                    text={t("my_week.copy")}
                  />
                </div>
              </div>

              <div className="integration__secondStep">
                <p>
                  2. {t("my_week.favourite_calendar").split("__")[0]}
                  <a target="_blank" href="https://calendar.google.com/calendar/">
                    {t("my_week.google_calendar")}
                  </a>
                  ,{" "}
                  <a target="_blank" href="https://outlook.live.com/calendar/0/view/month">
                    {t("my_week.outlook")}
                  </a>
                  {t("my_week.favourite_calendar").split("__")[1]}
                </p>
              </div>

              <div className="integration__thirdStep">
                <p>3. {t("my_week.create_calendar")}</p>
                <img src={GoogleScreen} />
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
