import "./default_styles.scss";
import React, { useRef } from "react";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

export const InputNumber = ({
  value,
  setValue,
  max,
  min,
  step = 0.5,
  style,
  roundValue = {
    isRoundValue: false,
    roundStep: 0,
  },
  onKeyDown = () => {},
}) => {
  const ref = useRef();
  return (
    <div className="input-number__container">
      <input
        onKeyDown={onKeyDown}
        ref={ref}
        style={style}
        max={max}
        min={min}
        step={step}
        value={value}
        onChange={(e) => {
          const val = ref.current.value;

          setValue(val);
        }}
        onBlur={(e) => {
          const val = +ref.current.value;

          if (val < min) {
            setValue(min);
          } else if (val > max) {
            setValue(max);
          } else if (roundValue.isRoundValue) {
            setValue(Number(Math.round(val * roundValue.roundStep) / roundValue.roundStep).toString());
          }
        }}
        type="number"
        className="input-number__field"
      />
      <div className="input-number__arrows">
        <TeambookIcon
          name={icons.ARROW_UP}
          onClick={() => {
            const val = +ref.current.value;

            if (val + step <= max) {
              setValue(val + step);
            } else {
              setValue(val);
            }
          }}
        />
        <TeambookIcon
          name={icons.ARROW_DOWN}
          onClick={() => {
            const val = +ref.current.value;

            if (val - step >= min) {
              setValue(val - step);
            } else {
              setValue(val);
            }
          }}
        />
      </div>
    </div>
  );
};
