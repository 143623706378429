const DaysRow = ({ date, days }) => {
  return (
    <div className="actuals__by-project__days-row">
      <div className="actuals__by-project__days-row__spacer" />

      <div className="actuals__by-project__days-row__days">
        {Array.apply(null, Array(days)).map((_, index) => {
          const day = date.plus({ days: index });
          const isWeekend = day.weekday === 6 || day.weekday === 7;
          const sundayFirst = isWeekend && index === 0;
          const mondaySecond = day.weekday === 1 && index === 1;

          return (
            <div
              key={`day-${index}`}
              className={`actuals__by-project__days-row__day ${isWeekend ? "weekend" : ""} ${
                sundayFirst ? "sunday-first" : ""
              } ${mondaySecond ? "monday-second" : ""} ${index === 0 ? "first-column" : ""}`}
            >
              <p className="actuals__by-project__days-row__day-text" id={`days-row-text-${index}`}>
                {day.toFormat("ccc d")}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DaysRow;
