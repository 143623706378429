import React, { useEffect, useState, useRef } from "react";
import Api from "../../Api";
import { WhiteTooltip } from "../default_components/Tooltips";
import { TeambookCheckbox } from "../default_components/TeambookCheckbox";
import $ from "jquery";

const ActualLog = ({
  log,
  index,
  updateActuals,
  projectFor,
  date,
  type = "regular",
  openEditLoggingWindow,
  changeContextMenu,
  changeTooltip,
  occupations,
  activities,
  copyEnabled,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isApproved, setIsApproved] = useState(log.approved);
  const logRef = useRef(null);

  useEffect(() => {
    setIsApproved(log.approved);
  }, [log]);

  const Approve = (e) => {
    e.stopPropagation();
    e.preventDefault();

    Api.ActualLogs.approve_log([log.id], !isApproved).then(() => {
      updateActuals();
      setIsApproved(!isApproved);
    });
  };

  return (
    <div
      ref={logRef}
      key={`day-${date.day}-project-${index}`}
      className="actuals__log"
      onMouseEnter={(e) => {
        e.preventDefault();
        setIsHovered(true);
        let elemOffset = $(e.target);
        if (logRef.current) {
          const elemOffset = $(logRef.current);
          changeTooltip({
            open: true,
            bookingDuration: log.duration,
            comment: log.comment,
            projectName: projectFor(log)?.name || "",
            projectCode: projectFor(log)?.code || "",
            occupation: occupations.find((occupation) => occupation.id === log.task_id)?.name || "",
            activity: activities.find((activity) => activity.id === log.payroll_item_id)?.name || "",
            positionX: elemOffset.offset().left + elemOffset.width() / 2,
            positionY: elemOffset.offset().top,
          });
        }
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        changeTooltip({ open: false });
      }}
      onClick={() => {
        if (!copyEnabled) {
          openEditLoggingWindow(log);
        }
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        changeContextMenu({
          open: true,
          pos: { x: e.pageX, y: e.pageY },
          log: log,
        });
      }}
      style={{
        borderColor: projectFor(log)?.color,
        color: log.approved ? "var(--background-1)" : "unset",
        background: isApproved ? projectFor(log)?.color : "unset",
        borderStyle: log.created_with === "ui" ? "solid" : "dashed",
      }}
    >
      <p className="actuals__log-project-name">{projectFor(log)?.code}</p>

      {type === "approve" && (log.approved || isHovered) && (
        <WhiteTooltip title={`Approve`}>
          <TeambookCheckbox
            type="checkbox"
            className="actuals__button-approve"
            onChange={Approve}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            checked={isApproved}
            value={isApproved}
            style={{ width: 16, marginLeft: "auto", marginRight: 7 }}
          />
        </WhiteTooltip>
      )}

      <p className="actuals__log-duration" style={{ width: 30 }}>
        {log.duration / 60}h
      </p>
    </div>
  );
};

export default ActualLog;
