import MonthRow from "./components/MonthRow";
import DaysRow from "./components/DaysRow";
import "./by_project.scss";
import ProjectRow from "./components/ProjectRow";
import { useEffect, useMemo, useState } from "react";
import Api from "../../../../Api";
import { useProfileStore } from "../../../../stores/profileStore";
import { useActualsStore } from "../../../../stores/actualsStore";
import AddProjectDialog from "../../../planner/modals/AddProjectDialog";
import { shallow } from "zustand/shallow";
import TotalRow from "./components/TotalRow";

const ProjectsTimesheet = ({ date, projects, actualLogs, actualsZoom, updateActuals, tasks, payrollItems }) => {
  const [displayedProjects, setDisplayedProjects] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [projectPopupId, setProjectPopupId] = useState();
  const [selectedPayrollItem, setSelectedPayrollItem] = useState();
  const [addProjectModalOpen] = useActualsStore((state) => [state.timesheetAddProjectOpen], shallow);

  let payrollItemOptions = payrollItems.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  payrollItemOptions.push({ value: null, label: "No payroll item" });

  useEffect(() => {
    Api.Bookings.get(
      [useProfileStore.getState().profile.id],
      date,
      date.plus({ days: actualsZoom === "week" ? 7 : 30 })
    ).then((response) => {
      setBookings(response.data);
    });

    useActualsStore.getState().setTimesheetAddedProjects([]);
  }, []);

  console.log(useActualsStore.getState().timesheetAddedProjects);

  useEffect(() => {
    const projectsWithData = projects.filter((project) => {
      if (project.name === "Google Integration") {
        return false;
      }

      if (actualLogs.map((actual) => actual.project_id).includes(project.id)) {
        return true;
      } else if (
        bookings.map((booking) => booking.project_id).includes(project.id) &&
        (project.kind !== "time_off" || project.active === true)
      ) {
        return true;
      } else if (
        useActualsStore
          .getState()
          .timesheetAddedProjects.map((project) => project.id)
          .includes(project.id)
      ) {
        return true;
      }

      return false;
    });

    setDisplayedProjects(projectsWithData);
  }, [actualLogs, projects, bookings, useActualsStore.getState().timesheetAddedProjects]);

  const days = useMemo(() => {
    if (actualsZoom === "week") {
      return 7;
    }

    return date.daysInMonth;
  }, [actualsZoom, date]);

  const closeAddProjectsWindow = () => {
    useActualsStore.getState().setTimesheetAddProjectOpen(false);
    useActualsStore.getState().setTimesheetAddedProjects([]);
  };

  return (
    <div className="actuals__by-project">
      <MonthRow date={date} zoom={actualsZoom} days={days} />
      <DaysRow date={date} days={days} />

      {displayedProjects.map((project) => (
        <ProjectRow
          project={project}
          date={date}
          days={days}
          actuals={actualLogs}
          updateActuals={updateActuals}
          tasks={tasks}
          projectPopupId={projectPopupId}
          setProjectPopupId={setProjectPopupId}
          selectedPayrollItem={selectedPayrollItem}
          setSelectedPayrollItem={setSelectedPayrollItem}
          payrollItemOptions={payrollItemOptions}
        />
      ))}

      {displayedProjects.length > 0 && <TotalRow date={date} days={days} actuals={actualLogs} />}

      {addProjectModalOpen && (
        <AddProjectDialog
          handleClose={closeAddProjectsWindow}
          allProjects={projects}
          visibleProjectIds={displayedProjects.map((project) => project.id)}
          addProjects={(projects) => {
            const alreadyAddedProjects = useActualsStore.getState().timesheetAddedProjects;
            useActualsStore.getState().setTimesheetAddedProjects([...alreadyAddedProjects, ...projects]);
            useActualsStore.getState().setTimesheetAddProjectOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default ProjectsTimesheet;
