import betaImage from "../../../default_images/beta.png";
import { Switch } from "@mui/material";
import TeambookWhiteButton from "../../TeambookWhiteButton";
import Api from "../../../../Api";
import TeambookBlueButton from "../../TeambookBlueButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";
import { useNotificationStore } from "../../../../stores/notificationStore";
import { shallow } from "zustand/shallow";

const GoogleTab = ({ open }) => {
  const { t } = useTranslation();

  const [googleIntegration, setGoogleIntegration] = useState();

  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);

  useEffect(() => {
    fetchGoogleCalendar();
  }, []);

  const successGoogle = (codeResponse) => {
    Api.Integrations.Google.createCalendar(codeResponse.code).then(() => {
      fetchGoogleCalendar();
    });
  };

  const errorGoogle = (response) => {
    console.log(response);
  };

  const fetchGoogleCalendar = () => {
    Api.Integrations.Google.fetchCalendar().then((res) => {
      setGoogleIntegration(res.data);
    });
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => successGoogle(codeResponse),
    onError: errorGoogle,
    scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
    access_type: "offline",
    prompt: "consent",
    flow: "auth-code",
  });

  const changeGoogleIntegration = (type, value) => {
    Api.Integrations.Google.updateSettings({ [type]: value }).then(() => {
      fetchGoogleCalendar();

      setInAppNotification("Changes were saved!");
    });
  };

  if (!open) {
    return null;
  }

  if (!googleIntegration) {
    return (
      <div className="calendar-integration deactivated">
        <img src={betaImage} alt={"BETA IMAGE"} className="beta-image" />
        <TeambookBlueButton text={"Connect to Google"} onClick={loginGoogle} />
      </div>
    );
  }

  return (
    <div className="calendar-integration">
      <img src={betaImage} alt={"BETA IMAGE"} className="beta-image" />
      <div className="google-integration__row">
        <p title="pushing Bookings every hour to Google Calendar">Push Bookings to Google Calendar</p>
        <Switch
          id={"integrateGooglePush"}
          checked={googleIntegration.events_push}
          onChange={(event) => {
            if (event.target.checked) {
              changeGoogleIntegration("events_push", true);
            } else {
              changeGoogleIntegration("events_push", false);
            }
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>

      <p className="helper-text">Bookings will be pushed as soon as created or changed</p>

      <div className="google-integration__row">
        <p title="pulling Google Events every hour, fetching events for next 2 weeks">
          Pull events from Google Calendar
        </p>
        <Switch
          id={"integrateGooglePush"}
          checked={googleIntegration.events_pull}
          onChange={(event) => {
            if (event.target.checked) {
              changeGoogleIntegration("events_pull", true);
            } else {
              changeGoogleIntegration("events_pull", false);
            }
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>

      <p className="helper-text">
        Bookings are not editable nor deletable <br /> Synchronisation runs every hour (at XX:00) for upcoming 1 month
        date-range{" "}
      </p>

      <TeambookWhiteButton
        text={t("disconnect")}
        onClick={() => {
          confirm("All associated Google Calendar events will be DELETED in Teambook AND in Google. Are you sure?") &&
            Api.Integrations.Google.deleteCalendar().then(() => {
              fetchGoogleCalendar();
            });
        }}
        className="disconnect-button"
      />
    </div>
  );
};

export default GoogleTab;
